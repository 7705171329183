import React from "react";
import { Container, Text } from "@atoms";

const DevError = () => {
  return (
    <Container className="flex min-h-[50vh] flex-col items-center justify-center pb-20 pt-28 md:pb-28 md:pt-48">
      <Text variant="h3" className="mb-4">
        The site is still in progress.
      </Text>
      <Text variant="lg">
        Don&apos;t worry. Chances are the page you are looking for is not done
        yet.
      </Text>
    </Container>
  );
};

export default DevError;
